<template>
  <v-layout column>
    <DefaultSubtitle class="mx-4 mt-4" color="primary">Status importu</DefaultSubtitle>
    <DefaultLabel class="mx-4 mt-4">{{ status }}</DefaultLabel>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
  },
  computed: {
    status() {
      switch(this.value.state) {
        case 1:
            return "Dane zostały przesłane";
        case 2:
            return "Trwa import danych";
        case 3:
            return "Import zakończony";
        case 4:
            return "Import się nie udał, skontaktuj się z obsługą klienta"
        default:
            return '';
      }
    },
  },
  components: {
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
  },
};
</script>
